<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #lotterySettings="item">
              <lottery-setting-form
                :field="item.field"
                v-model="data.lotterySettings"
              />
            </template>
            <template #username="item">
              <b-row>
                <b-col :sm="6">
                  <b-form-group :label-for="item.field.key">
                    <validation-provider
                      #default="{ errors }"
                      :vid="item.field.key"
                      :name="$t(item.field.label)"
                      :rules="item.field.rules"
                    >
                      <label>
                        {{ $t(item.field.label) }}
                      </label>
                      <input
                        type="hidden"
                        v-model="data[item.field.key]"
                        :name="item.field.key"
                      />
                      <b-row>
                        <n-single-select
                          v-model="username.first"
                          :options="alphabet"
                          :clearable="true"
                          :loading="checkUsernameLoading"
                          class="col-6"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          :readonly="true"
                        ></n-single-select>
                        <n-single-select
                          v-model="username.second"
                          :options="alphabet"
                          :clearable="false"
                          :loading="checkUsernameLoading"
                          class="col-6"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          :readonly="true"
                        ></n-single-select>
                      </b-row>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                v-if="$can('update', 'company')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import NSingleSelect from "@/components/NSingleSelect";
import FormInput from "./formInput";
import Alphabet from "@/data/alphabet";
import BetSettingMenu from "./BetSettingMenu.vue";
import LotterySettingForm from "./LotterySettingForm";

const CompanyRepository = Repository.get("company");
const LotteryRepository = Repository.get("lottery");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    BetSettingMenu,
    LotterySettingForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        name: null,
        phone: null,
        username: null,
        password: null,
        commissionNumTwo: 0,
        commissionNumThree: 0,
        share: 0,
        ccy: null,
        lotteryIds: [],
        lotterySettings: [],
        isEnable: true,
        isReadonly: false,
        maxWinDay: 0,
        maxTodayBet: 0,
        minBetInvoice: 0,
        maxBetInvoice: 0,
        minBetGame: 0,
        maxBetGame: 0,
        minBetPerNumTwo: 0,
        maxBetPerNumTwo: 0,
        minBetPerNumThree: 0,
        maxBetPerNumThree: 0,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      username: {
        first: null,
        second: null,
      },
    };
  },
  watch: {
    "username.first": function (value) {
      this.data.username = `${this.username.first}${
        this.username.second ? this.username.second : ""
      }`;
    },
    "username.second": function (value) {
      if (this.username.first) {
        this.data.username = `${this.username.first}${
          this.username.second ? this.username.second : ""
        }`;
      }
    },
  },
  mounted() {
    this.show();
  },
  methods: {
    getLottery() {
      LotteryRepository.listActive().then((response) => {
        const data = response?.data?.data;
        if (data) {
          data.forEach((element) => {
            this.data.lotterySettings.push({
              lotteryName: element.name,
              lotteryNameKh: element.nameKh,
              lotteryId: element.id,
              payoutTwoDigit: null,
              payoutThreeDigit: null,
              commissionTypeThree: 1,
              commissionTypeTwo: 1,
              commissionNumTwo: null,
              commissionNumThree: null,
            });
          });
        }
      });
    },
    show() {
      CompanyRepository.show(this.$route.params.id).then((response) => {
        this.username.first = response.data.data.username.charAt(0);
        this.username.second = response.data.data.username.charAt(1);
        const data = response.data.data;
        if (data) {
          this.initData = {
            ...data,
          };
          if (!data.lotterySettings.length) {
            this.getLottery();
          }
        }
      });
    },
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          CompanyRepository.update(this.$route.params.id, this.data)
            .then((response) => {
              this.$router.push({
                name: "view-company",
                params: { id: response.data.data.id },
              });
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.editForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      if (value.key === "password" || value.key === "retypePassword") {
        value.rules = value.rules.replace("required|", "");
        value.rules = value.rules.replace("required", "");
      } else if (value.key === "givenCredit") {
        value.disabled = true;
      }

      return value;
    });

    const alphabet = [...Alphabet];
    return { fields, alphabet };
  },
};
</script>
